import logo from "../../../assets/images/CV-Logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState, useEffect, useRef } from "react";
import { IoClose } from "react-icons/io5";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { useAuth } from "../../modules/auth/core/AuthContext";
import { FaUserCircle } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import SignUpPopup from "../auth/SignUpPopup";
import { Link } from "react-router-dom";
// import { navigate } from 'react-router-dom';

const Header = () => {
  // const location = useLocation();clear
  // const fixedPaths = ["/Facility"];
  // const isFixed = fixedPaths.includes(location.pathname);
  const navigate = useNavigate();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(true);
  const [openMenu, setOpenMenu] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const { currentUser, logout } = useAuth();
  const headerRef = useRef<HTMLDivElement>(null);
  const megamenu = document.getElementById("menuopen");
  const openMegaMenu = (menuid: any) => {
    setOpenMenu(menuid);
    megamenu?.classList.add("openmegamenu");
    setOpenProfile(false);

    // document.body.style.overflow = 'hidden';
  };
  const logoutClicked = () => {
    setOpenProfile(false);
    logout();
    window.location.reload();
  };
  const myfavoritesClicked = () => {
    setOpenProfile(false);
    navigate("/myfavorites");
  };
  const closeMegaMenu = () => {
    setOpenMenu(null);
    megamenu?.classList.remove("openmegamenu");
    // document.body.style.overflow = 'unset';
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
  }, []);
  function assertIsNode(e: EventTarget | null): asserts e is Node {
    if (!e || !("nodeType" in e)) {
      throw new Error(`Node expected`);
    }
  }
  const handleOutsideClick = (ev: MouseEvent) => {
    assertIsNode(ev.target);
    if (headerRef.current && !headerRef.current.contains(ev.target)) {
      setOpenProfile(false);
    }
  };
  const openprofile = () => {
    if (currentUser) {
      setOpenProfile(!openProfile);
    } else {
      // navigate("/auth/signup");
      handleShowSignUpPopup();
    }
  };

  const openmobilemenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  const openMobMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  let prevScrollPos = window.pageYOffset;

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setIsHeaderFixed(currentScrollPos > 100);

    setIsScrollingDown(prevScrollPos < currentScrollPos);
    prevScrollPos = currentScrollPos;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop === 0) {
      document.body.classList.remove("scrolled");
    } else {
      document.body.classList.add("scrolled");
    }
  };

  // const handleMouseMove = (event:any) => {
  //     const mouseY = event.clientY;
  //     setIsHeaderFixed(mouseY <= 100);
  // };

  useEffect(() => {
    if (mobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    window.addEventListener("scroll", handleScroll);
    // window.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.body.style.overflow = "unset";
      window.removeEventListener("scroll", handleScroll);
      // window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [mobileMenu]);
  function getInitials(fullName: any) {
    if (fullName) {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

      let initials = [...fullName.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();

      return initials;
    } else {
      return "";
    }
  }

  const handleShowSignUpPopup = () => {
    setShowSignUpPopup(true);
  };

  const handleShowLoginPopup = () => {
    setShowSignUpPopup(false);
  };

  const handleClosePopup = () => {
    setShowSignUpPopup(false);
  };

  return (
    <>
      {showSignUpPopup && (
        <SignUpPopup
          handleShowSignUpPopup={handleShowSignUpPopup}
          showSignUpPopup={showSignUpPopup}
          handleShowLoginPopup={handleShowLoginPopup}
          handleClosePopup={handleClosePopup}
        />
      )}
      <div
        className={`w-[100%] bg-white z-30 shadow-sm fixed top-0 transition-all duration-500 scroll-up  ${
          isScrollingDown ? "scroll-down" : ""
        }`}
        id="cv-header"
        ref={headerRef}
      >
        <div
          id="menuopen"
          className={`w-[100%] h-[100%] fixed top-[80px] z-10 left-0 bg-borderclr hidden`}
        ></div>
        <header onMouseLeave={closeMegaMenu}>
          <div className="lg:max-w-[1800px] m-auto px-[24px]">
            <div className="bg-white py-[15px] flex items-center justify-between w-[100%]">
              <div className="flex items-center justify-between w-[100%] lg:w-auto">
                <div>
                  <Link to="/">
                    <img
                      src={logo}
                      alt="cv-logo"
                      className="w-[105px] lg:w-[148px] cursor-pointer"
                      // onClick={() => {
                      //   navigate("/");
                      // }}
                    />
                  </Link>
                </div>
                <div className="flex items-center gap-[16px] lg:hidden">
                  <button
                    className="btn bg-pc text-white rounded-full font-anchor font-medium py-[7px] px-[27px] "
                    onClick={openprofile}
                  >
                    {currentUser ? "My Account" : "Sign up"}
                  </button>
                  {openProfile && (
                    <div className="bg-white shadow-boxshadow h-auto absolute right-[0px] z-20 top-[70px] w-fit border-t-[5px] border-hoverclr rounded-b-[10px] pb-[20px] shadow-lg">
                      <div className="px-[20px] py-[10px] border-b border-b-hoverclr">
                        <h5 className=" font-semibold text-anchortext">
                          Account
                        </h5>
                        <div className="flex items-center gap-[10px] py-[10px]">
                          <div className="bg-hoverclr rounded-full p-[8px] text-white font-medium text-anchortext w-[40px] h-[40px] flex items-center justify-center">
                            {getInitials(currentUser?.name)}
                          </div>
                          <div className=" text-smalltext">
                            <p>{currentUser?.name}</p>
                            <a>{currentUser?.email}</a>
                          </div>
                        </div>
                      </div>
                      <div className="border-b border-b-hoverclr pb-[20px]">
                        <ul className="pt-[20px] px-[20px] flex flex-col gap-[10px]">
                          <li>
                            <a
                              href="/about"
                              className="text-[gray] pointer-events-none"
                            >
                              Profile
                            </a>
                          </li>
                          <li>
                            <a onClick={myfavoritesClicked}>My favorites</a>
                          </li>
                          {/* <li>
                          <a href="/about">Notification</a>
                        </li> */}
                        </ul>
                      </div>
                      <div>
                        <ul className="pt-[20px] px-[20px]">
                          <li>
                            <a onClick={logoutClicked}>Logout</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                  <div>
                    {mobileMenu ? (
                      <IoClose
                        className="text-pc"
                        size={"30px"}
                        onClick={openMobMenu}
                      />
                    ) : (
                      <GiHamburgerMenu
                        className="text-pc"
                        size={"30px"}
                        onClick={openMobMenu}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden lg:block">
                <nav className="flex items-center gap-[30px]">
                  <ul className="flex items-center gap-[20px]">
                    <li className="block">
                      <HashLink
                        className={`block text-anchortext  font-medium p-[10px] hover:bg-[#e9ecf0] hover:text-pc  hover:rounded-[8px] relative`}
                        onMouseEnter={() => openMegaMenu("")}
                        to="/#bestdestinations"
                      >
                        Top destinations
                      </HashLink>
                    </li>
                    <li className="block">
                      <HashLink
                        className={`block text-anchortext  font-medium p-[10px] hover:bg-[#e9ecf0] hover:text-pc  hover:rounded-[8px] relative `}
                        onMouseEnter={() => openMegaMenu("")}
                        to="/#topfacilities"
                      >
                        Top facilities
                      </HashLink>
                    </li>
                    <li className="block">
                      <HashLink
                        className="block text-anchortext  font-medium p-[10px] hover:bg-[#e9ecf0] hover:text-pc  hover:rounded-[8px]"
                        onMouseEnter={() => openMegaMenu("")}
                        to="/#servicesandtreatments"
                      >
                        Services and treatments
                      </HashLink>
                    </li>
                    <li
                      className="block group"
                      onMouseEnter={() => openMegaMenu("menu2")}
                    >
                      <a
                        className={`block text-anchortext  font-medium p-[10px] group-hover:bg-[#e9ecf0] group-hover:text-pc  group-hover:rounded-[8px] ${
                          openMenu === "menu2"
                            ? "bg-[#e9ecf0] text-pc font-semibold"
                            : ""
                        }`}
                      >
                        Help
                      </a>
                      {openMenu === "menu2" && (
                        <div
                          className="mega-menu bg-white shadow-boxshadow h-auto px-[20px] py-[10px] absolute z-20 top-[80px] w-fit border-t-[5px] border-hoverclr rounded-b-[10px]"
                          onMouseLeave={closeMegaMenu}
                        >
                          <ul className="pt-[20px]">
                            <li>
                              <a
                                // onClick={() => {
                                //   navigate(
                                //     "https://curevalue.org/common/how-it-works.php"
                                //   );
                                // }}
                                target="_blank"
                                href="https://curevalue.org/common/how-it-works.php"
                              >
                                How it works
                              </a>
                            </li>
                            <li>
                              <a
                                // onClick={() => {
                                //   navigate("/about-us");
                                // }}
                                target="_blank"
                                href="https://curevalue.org/common/about-us.php"
                              >
                                About Curevalue
                              </a>
                            </li>
                            <li>
                              <a
                                // onClick={() => {
                                //   navigate("/contact-us");
                                // }}
                                target="_blank"
                                href="https://curevalue.org/common/contact-us.php"
                              >
                                Contact us
                              </a>
                            </li>
                            <li>
                              <a
                                // onClick={() => {
                                //   navigate("/provider-help");
                                // }}
                                target="_blank"
                                href="https://curevalue.org/common/provider-help.php"
                              >
                                Provider help
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                  </ul>
                  <div className="w-auto">
                    <button
                      className="btn border border-pc bg-pc text-white rounded-full font-anchor font-medium lg:py-[12px] px-[27px] hover:bg-white hover:text-pc flex items-center"
                      onClick={openprofile}
                    >
                      {currentUser ? "My Account" : "Sign up"}
                      {currentUser && <FaUserCircle className="text-xl ml-2" />}
                    </button>
                    {openProfile && (
                      <div className="bg-white shadow-boxshadow h-auto absolute right-[30px] z-20 top-[80px] w-fit border-t-[5px] border-hoverclr rounded-b-[10px] pb-[20px] shadow-lg">
                        <div className="px-[20px] py-[10px] border-b border-b-hoverclr">
                          <h5 className=" font-semibold text-anchortext">
                            Account
                          </h5>
                          <div className="flex items-center gap-[10px] py-[10px]">
                            <div className="bg-hoverclr rounded-full p-[8px] text-white font-medium text-anchortext w-[40px] h-[40px] flex items-center justify-center">
                              {getInitials(currentUser?.name)}
                            </div>
                            <div className=" text-smalltext">
                              <p>{currentUser?.name}</p>
                              <a>{currentUser?.email}</a>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-b-hoverclr pb-[20px]">
                          <ul className="pt-[20px] px-[20px] flex flex-col gap-[10px]">
                            <li>
                              <a
                                href="/about"
                                className="text-[gray] pointer-events-none"
                              >
                                Profile
                              </a>
                            </li>
                            <li>
                              <a onClick={myfavoritesClicked}>My favorites</a>
                            </li>
                            {/* <li>
                            <a href="/about">Notification</a>
                          </li> */}
                          </ul>
                        </div>
                        <div>
                          <ul className="pt-[20px] px-[20px]">
                            <li>
                              <a onClick={logoutClicked}>Logout</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>

        {mobileMenu && (
          <div className="h-screen overflow-y-scroll bg-white z-20 p-[24px] relative">
            <ul className="flex flex-col items-start gap-[30px]">
              <li>
                <HashLink
                  className="text-anchortext "
                  to="/#bestdestinations"
                  onClick={openMobMenu}
                >
                  Top destinations
                </HashLink>
              </li>
              <li>
                <HashLink
                  className="text-anchortext "
                  to="/#topfacilities"
                  onClick={openMobMenu}
                >
                  Top facilities
                </HashLink>
              </li>
              <li>
                <HashLink
                  className="text-anchortext "
                  to="/#servicesandtreatments"
                  onClick={openMobMenu}
                >
                  Services and treatments
                </HashLink>
              </li>
              <li className="flex items-center justify-between w-[100%]">
                <a
                  className="text-anchortext capitalize w-full flex justify-between"
                  onClick={openmobilemenu}
                >
                  Help
                  {!openMobileMenu ? (
                    <MdKeyboardArrowRight className="text-pc text-[26px]" />
                  ) : (
                    <MdKeyboardArrowDown className="text-pc text-[26px]" />
                  )}
                </a>
              </li>
              {openMobileMenu && (
                <div className="mega-menu bg-white h-auto px-[20px] z-20">
                  <ul className="">
                    <li>
                      <a
                        // onClick={() => {
                        //   navigate("/how-it-works");
                        // }}
                        target="_blank"
                        href="https://curevalue.org/common/how-it-works.php"
                      >
                        How it works
                      </a>
                    </li>
                    <li>
                      <a
                        // onClick={() => {
                        //   navigate("/about-us");
                        // }}
                        target="_blank"
                        href="https://curevalue.org/common/about-us.php"
                      >
                        About Curevalue
                      </a>
                    </li>
                    <li>
                      <a
                        // onClick={() => {
                        //   navigate("/contact-us");
                        // }}
                        target="_blank"
                        href="https://curevalue.org/common/contact-us.php"
                      >
                        Contact us
                      </a>
                    </li>
                    <li>
                      <a
                        // onClick={() => {
                        //   navigate("/provider-help");
                        // }}
                        target="_blank"
                        href="https://curevalue.org/common/provider-help.php"
                      >
                        Provider help
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
