import { MdOutlineSegment } from "react-icons/md";
import { IoLanguage } from "react-icons/io5";
import { LiaCcVisa } from "react-icons/lia";
import { GrNext } from "react-icons/gr";
import { TiWeatherCloudy, TiWeatherSunny } from "react-icons/ti";
import { LiaCertificateSolid } from "react-icons/lia";
import sun from "../../../assets/images/sunny.png";
import { TbLicense } from "react-icons/tb";
import { FaLanguage } from "react-icons/fa";
import { PiMedalFill } from "react-icons/pi";
import { IoMdLock } from "react-icons/io";
import { useState } from "react";
import { CITY_IMAGE_CDN } from "../shared/urlConstants";
import cardimg from "../../../assets/images/city-default.png";
import AppointmentSchedule from "../../pages/AppointmentSchedule"
import CVPopup from "../shared/CVPopup";
const FacilityInnerCards = ({
  cardFor,
  facility,
  isBeforeLogin,
  handleShowSignUpPopup,
  city,
}: any) => {
  // const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  // const { showLoginPopup } = useLoginPopup();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState<any>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const description = facility.facility_description || "";
  const charLimit = 500;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLockBtnClick = () => {
    handleShowSignUpPopup();
  };
  // Manage the popup on clicking the text "Contact us for more details"
  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };
  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const cardId =
    cardFor == "Facility Description"
      ? "description"
      : cardFor == "City Overview"
      ? "overview"
      : cardFor == "Language Spoken"
      ? "language"
      : cardFor == "Visa Requirement"
      ? "visa-required"
      : cardFor == "Weather"
      ? "weather"
      : cardFor == "Accreditations"
      ? "accreditations"
      : "none";
  return (
    <>
      <div>
        {/* <LoginPopup /> */}

        <div className="" id={cardId}>
          <div className="mb-[30px] lg:mb-[52px] pb-[27px] lg:pb-[58px] border-b border-borderclr">
            <div className="flex items-center gap-[5px] pb-[16px]">
              {cardFor == "Facility Description" ? (
                <>
                  <MdOutlineSegment className="text-iconclr text-h3font lg:text-[35px]" />
                </>
              ) : cardFor == "City Overview" ? (
                <>
                  <MdOutlineSegment className="text-iconclr text-h3font lg:text-[35px]" />
                </>
              ) : cardFor == "Language Spoken" ? (
                <>
                  <FaLanguage className="text-iconclr text-h3font lg:text-[35px]" />
                </>
              ) : cardFor == "Visa Requirement" ? (
                <>
                  <TbLicense className="text-iconclr text-h3font lg:text-[35px]" />
                </>
              ) : cardFor == "Weather" ? (
                <>
                  <TiWeatherSunny className="text-iconclr text-h3font lg:text-[35px]" />
                </>
              ) : cardFor == "Accreditations" ? (
                <>
                  <PiMedalFill className="text-iconclr text-h3font lg:text-[35px]" />
                </>
              ) : (
                ""
              )}
              {/* <MdOutlineSegment className="text-iconclr text-h3font lg:text-[35px]" /> */}
              <h2 className="text-sc text-h3font font-semibold lg:text-[20px] lg:font-bold">
                {cardFor}
              </h2>
            </div>
            <div>
              {cardFor == "Facility Description" ? (
                <>
                  <div>
                    {isBeforeLogin ? (
                      <>
                        <div>
                          <div className="relative top-[300px] left-[80px] lg:top-[120px] lg:left-[350px] z-[5] flex-col items-center">
                            <div
                              onClick={handleLockBtnClick}
                              className="flex flex-col items-center text-center w-[180px] lg:w-[200px]"
                            >
                              <IoMdLock
                                size={"37px"}
                                className="text-cardpara mb-2"
                              />
                              <p>Create a free account or login to view</p>
                            </div>
                          </div>

                          <p
                            className={`text-[19px] lg:text-pfont/[26px] text-cardpara mb-[10px] ${
                              isBeforeLogin ? "blur-text" : ""
                            }`}
                          >
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Itaque velit laborum quod deserunt! Molestiae
                            ad aliquam minus! Autem nesciunt accusamus fugit
                            voluptatum, adipisci quibusdam a deserunt quod
                            sapiente officiis sequi, alias ullam blanditiis.
                            Tempore cumque iusto dicta perferendis accusantium
                            ad rem asperiores! Libero culpa mollitia nobis alias
                            maxime quos impedit aperiam soluta! Voluptatibus,
                            odit fuga. Harum quaerat laborum reiciendis,
                            doloribus minima nemo assumenda nisi, eum, magni
                            praesentium dolor eaque tempora adipisci natus!
                            Dolorem officia, nostrum dignissimos odit magni
                            tempora repudiandae modi expedita nobis, porro
                            voluptatibus non ipsam enim quas et eaque ipsum
                            praesentium. Quod laborum hic atque debitis
                            doloremque dicta.
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <p
                            className={`text-[19px] lg:text-pfont/[26px] text-cardpara mb-[10px] ${
                              isBeforeLogin ? "blur-text" : ""
                            }`}
                          >
                            {isExpanded || description.length <= charLimit
                              ? description
                              : `${description.substring(0, charLimit)}...`}
                          </p>
                          {description.length > charLimit && (
                            <button
                              onClick={toggleExpanded}
                              className="text-smalltext lg:text-anchortext 2xl:text-pfont underline text-[#006AF5] font-medium cursor-pointer"
                            >
                              {isExpanded ? "Read less" : "Read more"}
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {cardFor == "City Overview" ? (
                // className="flex  lg:flex-row flex-col gap-[10px] items-start"
                <section>
                  {/* className="lg:w-1/2 w-full" */}
                  <div>
                    {city?.image_urls && (
                      <img
                        src={
                          city?.image_name
                            ? `${CITY_IMAGE_CDN + city.image_name}`
                            : cardimg
                        }
                        alt={`${facility.city_name.toLowerCase()}`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = cardimg;
                        }}
                        className="w-[400px] h-[250px] m-auto rounded-[25px] object-cover  lg:mr-7 lg:float-left lg:mb-0 mb-7 float-none"
                      />
                    )}
                  </div>
                  {/* className="lg:w-1/2 w-full" */}
                  {/* className={`text-[19px] lg:text-pfont/[26px] text-cardpara mb-[10px] ${
                        isBeforeLogin ? "blur-text" : ""
                      }`} */}
                  <div>
                    <p>
                      {isExpanded || facility.curevalue_overview <= charLimit
                        ? facility.curevalue_overview
                        : `${facility.curevalue_overview.substring(
                            0,
                            charLimit
                          )}...`}
                    </p>
                    {facility.curevalue_overview.length > charLimit && (
                      <button
                        onClick={toggleExpanded}
                        className="text-smalltext lg:text-anchortext 2xl:text-pfont underline text-[#006AF5] font-medium cursor-pointer"
                      >
                        {isExpanded ? "Read less" : "Read more"}
                      </button>
                    )}
                  </div>
                </section>
              ) : (
                ""
              )}

              {cardFor == "Language Spoken" ? (
                <>
                  {isBeforeLogin ? (
                    <>
                      <div>
                        <div className="relative top-[300px] left-[80px] lg:top-[120px] lg:left-[350px] z-[5] flex-col items-center">
                          <div
                            onClick={handleLockBtnClick}
                            className="flex flex-col items-center text-center w-[180px] lg:w-[200px]"
                          >
                            <IoMdLock
                              size={"37px"}
                              className="text-cardpara mb-2"
                            />
                            <p>Create a free account or login to view</p>
                          </div>
                        </div>
                        <p
                          className={`text-[19px] lg:text-pfont/[26px] text-cardpara mb-[10px] ${
                            isBeforeLogin ? "blur-text" : ""
                          }`}
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Itaque velit laborum quod deserunt! Molestiae ad
                          aliquam minus! Autem nesciunt accusamus fugit
                          voluptatum, adipisci quibusdam a deserunt quod
                          sapiente officiis sequi, alias ullam blanditiis.
                          Tempore cumque iusto dicta perferendis accusantium ad
                          rem asperiores! Libero culpa mollitia nobis alias
                          maxime quos impedit aperiam soluta! Voluptatibus, odit
                          fuga. Harum quaerat laborum reiciendis, doloribus
                          minima nemo assumenda nisi, eum, magni praesentium
                          dolor eaque tempora adipisci natus! Dolorem officia,
                          nostrum dignissimos odit magni tempora repudiandae
                          modi expedita nobis, porro voluptatibus non ipsam enim
                          quas et eaque ipsum praesentium. Quod laborum hic
                          atque debitis doloremque dicta.
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        {facility.languages_spoken &&
                          facility.languages_spoken.map((language: any) => {
                            return (
                              <>
                                <p
                                  className={`text-pfont text-cardpara font-medium ${
                                    isBeforeLogin ? "blur-text" : ""
                                  }`}
                                >
                                  {language} (Fluent)
                                </p>
                              </>
                            );
                          })}
                      </div>
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              {cardFor == "Visa Requirement" ? (
                <>
                  {isBeforeLogin ? (
                    <>
                      <div>
                        <div className="relative top-[300px] left-[80px] lg:top-[120px] lg:left-[350px] z-[5] flex-col items-center">
                          <div
                            onClick={handleLockBtnClick}
                            className="flex flex-col items-center text-center w-[180px] lg:w-[200px]"
                          >
                            <IoMdLock
                              size={"37px"}
                              className="text-cardpara mb-2"
                            />
                            <p>Create a free account or login to view</p>
                          </div>
                        </div>
                        <p
                          className={`text-[19px] lg:text-pfont/[26px] text-cardpara mb-[10px] ${
                            isBeforeLogin ? "blur-text" : ""
                          }`}
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Itaque velit laborum quod deserunt! Molestiae ad
                          aliquam minus! Autem nesciunt accusamus fugit
                          voluptatum, adipisci quibusdam a deserunt quod
                          sapiente officiis sequi, alias ullam blanditiis.
                          Tempore cumque iusto dicta perferendis accusantium ad
                          rem asperiores! Libero culpa mollitia nobis alias
                          maxime quos impedit aperiam soluta! Voluptatibus, odit
                          fuga. Harum quaerat laborum reiciendis, doloribus
                          minima nemo assumenda nisi, eum, magni praesentium
                          dolor eaque tempora adipisci natus! Dolorem officia,
                          nostrum dignissimos odit magni tempora repudiandae
                          modi expedita nobis, porro voluptatibus non ipsam enim
                          quas et eaque ipsum praesentium. Quod laborum hic
                          atque debitis doloremque dicta.
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center gap-[7px] py-[20px]">
                        {facility.visa_required == 5 ? (
                          <>
                            <p
                              className={`text-pfont text-iconclr font-medium ${
                                isBeforeLogin ? "blur-text" : ""
                              }`}
                            >
                              American citizens do not require a Visa to travel
                              to {facility.country_name}
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              className={`text-pfont text-iconclr font-medium ${
                                isBeforeLogin ? "blur-text" : ""
                              }`}
                            >
                              American citizens require a Visa to travel to{" "}
                              {facility.country_name}
                            </p>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              {cardFor == "Weather" ? (
                <>
                  {isBeforeLogin ? (
                    <>
                      <div>
                        <div className="relative top-[300px] left-[80px] lg:top-[120px] lg:left-[350px] z-[5] flex-col items-center">
                          <div
                            onClick={handleLockBtnClick}
                            className="flex flex-col items-center text-center w-[180px] lg:w-[200px]"
                          >
                            <IoMdLock
                              size={"37px"}
                              className="text-cardpara mb-2"
                            />
                            <p>Create a free account or login to view</p>
                          </div>
                        </div>
                        <p
                          className={`text-[19px] lg:text-pfont/[26px] text-cardpara mb-[10px] ${
                            isBeforeLogin ? "blur-text" : ""
                          }`}
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Itaque velit laborum quod deserunt! Molestiae ad
                          aliquam minus! Autem nesciunt accusamus fugit
                          voluptatum, adipisci quibusdam a deserunt quod
                          sapiente officiis sequi, alias ullam blanditiis.
                          Tempore cumque iusto dicta perferendis accusantium ad
                          rem asperiores! Libero culpa mollitia nobis alias
                          maxime quos impedit aperiam soluta! Voluptatibus, odit
                          fuga. Harum quaerat laborum reiciendis, doloribus
                          minima nemo assumenda nisi, eum, magni praesentium
                          dolor eaque tempora adipisci natus! Dolorem officia,
                          nostrum dignissimos odit magni tempora repudiandae
                          modi expedita nobis, porro voluptatibus non ipsam enim
                          quas et eaque ipsum praesentium. Quod laborum hic
                          atque debitis doloremque dicta.
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center gap-[7px] py-[20px]">
                        {/* <img src={sun} alt="" className="max-w-[70px]" /> */}
                        <p
                          className={`text-pfont text-iconclr font-medium ${
                            isBeforeLogin ? "blur-text" : ""
                          }`}
                        >
                          {facility.average_weather}
                        </p>
                      </div>
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              {cardFor == "Accreditations" ? (
                <>
                  {isBeforeLogin ? (
                    <>
                      <div>
                        <div className="relative top-[300px] left-[80px] lg:top-[120px] lg:left-[350px] z-[5] flex-col items-center">
                          <div
                            onClick={handleLockBtnClick}
                            className="flex flex-col items-center text-center w-[180px] lg:w-[200px]"
                          >
                            <IoMdLock
                              size={"37px"}
                              className="text-cardpara mb-2"
                            />
                            <p>Create a free account or login to view</p>
                          </div>
                        </div>
                        <p
                          className={`text-[19px] lg:text-pfont/[26px] text-cardpara mb-[10px] ${
                            isBeforeLogin ? "blur-text" : ""
                          }`}
                        >
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Itaque velit laborum quod deserunt! Molestiae ad
                          aliquam minus! Autem nesciunt accusamus fugit
                          voluptatum, adipisci quibusdam a deserunt quod
                          sapiente officiis sequi, alias ullam blanditiis.
                          Tempore cumque iusto dicta perferendis accusantium ad
                          rem asperiores! Libero culpa mollitia nobis alias
                          maxime quos impedit aperiam soluta! Voluptatibus, odit
                          fuga. Harum quaerat laborum reiciendis, doloribus
                          minima nemo assumenda nisi, eum, magni praesentium
                          dolor eaque tempora adipisci natus! Dolorem officia,
                          nostrum dignissimos odit magni tempora repudiandae
                          modi expedita nobis, porro voluptatibus non ipsam enim
                          quas et eaque ipsum praesentium. Quod laborum hic
                          atque debitis doloremque dicta.
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center gap-[7px] py-[20px]">
                        {facility.accreditations.length > 0 ? (
                          <>
                            {facility.accreditations.map(
                              (accreditation: any) => {
                                return (
                                  <>
                                    <p
                                      className={`text-[10px] me-4 xl:text-anchortext font-medium px-[8px] py-[2px] treatmentclr1 ${
                                        isBeforeLogin ? "blur-text" : ""
                                      }`}
                                    >
                                      {accreditation}
                                    </p>
                                  </>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <>
                            <p
                              className={`text-pfont text-iconclr font-medium ${
                                isBeforeLogin ? "blur-text" : ""
                              }`}
                            >
                              <button onClick={handlePopupOpen}>Contact us for more details</button>
                            </p>
                            {/* Conditionally render the popup */}
                            {isPopupOpen && (
                              // <CVPopup onClose={handlePopupClose} isClosable={true}>
                              //   <p>Here are the contact details you requested!</p>
                              // </CVPopup>
                              <CVPopup
                              onClose={() => {
                                handlePopupClose();
                              }}
                              isClosable
                            >
                              <AppointmentSchedule
                                treatmentId={selectedTreatment}
                                facility={facility}
                                onClose={() => {
                                  handlePopupClose();
                                }}
                              ></AppointmentSchedule>
                              </CVPopup>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </div>

            {/* <a
          href="#"
          className="text-[19px] font-semibold text-cardpara underline underline-offset-2 flex items-center gap-[5px] pt-[14px]"
        >
          Show more
          <GrNext className="text-anchortext text-cardpara" />
        </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilityInnerCards;
