import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

import * as Yup from "yup";

import { useEventEmmiter } from "../shared/eventEmmiter";

import { useNavigate } from "react-router-dom";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import Loader from "../shared/Loader";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginButton from "../../pages/LoginButton";
import {
  CheckEmailExists,
  forgetPasswordInit,
  getUserByToken,
  loginUser,
  postSocialMediaData,
  registerUser,
  useAuth,
} from "../../modules/auth";

const initialValues = {
  email: "",
  password: "",
  name: "",
};
const clientId = process.env.REACT_APP_GOAUTH_CLIENT_UD
  ? process.env.REACT_APP_GOAUTH_CLIENT_UD
  : "";

const SignUpPopup = ({
  handleShowSignUpPopup,
  showSignUpPopup,
  handleShowLoginPopup,
  handleClosePopup,
  popupDetailMainText,
  popupDetailSubText,
}: any) => {
  const eventEmmiter = useEventEmmiter();
  const [loading, setLoading] = useState(false);
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);
  const [isEmailExists, setIsEmailExists] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(true);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showLoginSuccessPopup, setShowLoginSuccessPopup] = useState(false);
  const [showRegisterSuccessPopup, setShowRegisterSuccessPopup] =
    useState(false);
  const [userEmail, setUserEmail] = useState("");
  const { saveAuth, setCurrentUser, currentUser } = useAuth();
  const navigate = useNavigate();
  const outerDivRef = useRef(null);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetPasswordMsg, setResetPasswordMsg] = useState("");

  let signupSchema;

  if (showEmailPopup) {
    signupSchema = Yup.object().shape({
      email: Yup.string()
        .email("Invalid email")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),
    });
  }
  if (showRegisterPopup) {
    signupSchema = Yup.object().shape({
      name: Yup.string().required("Name is required"),

      password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Password is required"),
    });
  }

  if (showLoginPopup) {
    signupSchema = Yup.object().shape({
      password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Password is required"),
    });
  }

  const checkEmailExists = async (e: any) => {
    e.preventDefault();
    if (!formik.values.email) return;
    setUserEmail(formik.values.email);
    let payload = {
      email: formik.values.email,
    };
    let { data: auth, error: err } = await CureValueService<any, any>(
      CheckEmailExists,
      payload,
      {
        setIsLoading: setLoading,
      }
    );
    if (auth) {
      setIsEmailExists(false);
      setShowEmailPopup(false);
      setShowRegisterPopup(false);
      setShowLoginPopup(true);
      setIsResetPassword(false);
      setResetPasswordMsg("");
    } else {
      setIsEmailExists(false);
      setShowEmailPopup(false);
      setShowRegisterPopup(true);
    }
  };

  const RegisterUser = async (e: any) => {
    e.preventDefault();
    // window.alert(`${formik.values.name}`);
    // window.alert(`${formik.values.password}`);
    // window.alert(`${userEmail}`);
    let payload = {
      email: userEmail,
      password: formik.values.password,
      name: formik.values.name,
    };
    let { data: auth, error: err } = await CureValueService<any, any>(
      registerUser,
      payload,
      {
        setIsLoading: setLoading,
      }
    );
    if (auth) {
      const { data: user, error: userError } = await CureValueService(
        getUserByToken,
        auth.api_token
      );
      if (user) {
        auth.data = user;
        saveAuth(auth);
        setCurrentUser(user);
        setShowRegisterPopup(false);
        setShowRegisterSuccessPopup(true);
        // handleClosePopup();
        // window.location.reload();
      }
    }
  };

  const LoginUser = async (e: any) => {
    e.preventDefault();
    // window.alert(`${formik.values.name}`);
    // window.alert(`${formik.values.password}`);
    // window.alert(`${userEmail}`);
    let payload = {
      email: userEmail,
      password: formik.values.password,
    };
    const { data: loginResponse, error: loginError } = await CureValueService(
      loginUser,
      payload
    );
    if (loginError) {
      saveAuth(undefined);
    } else {
      const { data: user, error: userError } = await CureValueService(
        getUserByToken,
        loginResponse.api_token
      );
      if (user) {
        loginResponse.data = user;
        saveAuth(loginResponse);
        setCurrentUser(loginResponse);
        // handleClosePopup();
        // window.location.reload();
        setShowLoginPopup(false);
        setShowLoginSuccessPopup(true);
      }
    }
  };

  const handleContinueBtn = (event: any) => {
    event.stopPropagation();
    setShowEmailPopup(false);
    setShowRegisterPopup(false);
    setShowLoginPopup(false);
    setShowLoginSuccessPopup(false);
    handleClosePopup();
    window.location.reload();
  };

  const handleBackFromLogin = (event: any) => {
    event.stopPropagation();
    setShowEmailPopup(true);
    setShowRegisterPopup(false);
    setShowLoginPopup(false);
    setShowLoginSuccessPopup(false);
  };

  const handleBackFromRegister = (event: any) => {
    event.stopPropagation();
    setShowRegisterPopup(false);
    setShowEmailPopup(true);
    setShowLoginPopup(false);
    setShowLoginSuccessPopup(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: signupSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      let payload = {
        email: values.email,
      };
      let { data: auth, error: err } = await CureValueService<any, any>(
        registerUser,
        payload,
        {
          setIsLoading: setLoading,
        }
      );
      if (auth) {
        const { data: user, error: userError } = await CureValueService(
          getUserByToken,
          auth.api_token
        );
        if (user) {
          auth.data = user;
          saveAuth(auth);
          setCurrentUser(user);
          handleClosePopup();
          window.location.reload();
        }
      }
    },
  });
  const handleLogin = async (googleData: any) => {
    formik.setSubmitting(true);
    let { data: auth, error: err } = await CureValueService(
      postSocialMediaData,
      { data: googleData },
      {
        setIsLoading: setLoading,
      }
    );

    if (err) {
      saveAuth(undefined);
      formik.setSubmitting(false);
    }
    if (auth) {
      let { data: user } = await CureValueService(
        getUserByToken,
        auth.api_token,
        {
          setIsLoading: setLoading,
        }
      );
      if (user) {
        auth.data = user;
        formik.setSubmitting(false);
        saveAuth(auth);
        setCurrentUser(user);
        handleClosePopup();
        window.location.reload();
      }
    }
  };

  const handleClickOutside = (event: any) => {
    if (outerDivRef.current && outerDivRef.current === event.target) {
      handleClosePopup();
      if (currentUser) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleForgotPassword = async (event: any) => {
    let { data, error } = await CureValueService(
      forgetPasswordInit,
      { email: userEmail },
      {
        setIsLoading: setLoading,
      }
    );
    if (data) {
      setIsResetPassword(true);
      setResetPasswordMsg(
        "Reset password link is sent to your registered email."
      );
    }
  };

  return (
    <>
      {showEmailPopup ? (
        <>
          <div
            className="fixed inset-0 flex items-center justify-center bg-[#787878cc] bg-opacity-25 z-20"
            ref={outerDivRef}
          >
            <form
              className="w-[470px] shadow-lg p-[20px] rounded-lg border bg-white"
              onSubmit={checkEmailExists}
              noValidate
            >
              <div
                className="border-[#1C304A85] pb-[5px] mb-[25px]"
                onClick={handleClickOutside}
              >
                <h2 className="text-h2font lg:text-[25px] text-lightblack font-bold">
                  Log in or create an account {popupDetailMainText}
                  {/* {popupFor == "favourite" ? "to save your favorite" : ""} */}
                </h2>
                <p className="text-[15px] lg:text-[19px] text-[#4F4F4F]">
                  {popupDetailSubText}
                  {/* {popupFor == "favourite"
                    ? "Save your favorite and compare them side by side"
                    : ""} */}
                </p>
                <p className="text-[15px] lg:text-[19px] text-[#4F4F4F]">
                  It's free!
                  {/* {popupFor == "favourite"
                    ? "Save your favorite and compare them side by side"
                    : ""} */}
                </p>
              </div>

              {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {!isGoogleAuthLoading && (
                <>
                  <div className="flex flex-col gap-[4px] mb-[10px]">
                    <label className="text-pfont text-lightblack font-semibold">
                      Email address
                    </label>
                    <input
                      placeholder="Example@gmail.com"
                      {...formik.getFieldProps("email")}
                      className={clsx(
                        "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                        {
                          "is-invalid":
                            formik.touched.email && formik.errors.email,
                        },
                        {
                          "is-valid":
                            formik.touched.email && !formik.errors.email,
                        }
                      )}
                      type="email"
                      name="email"
                      autoComplete="off"
                    />
                    {formik.touched.email &&
                      formik.errors.email &&
                      !isGoogleAuthLoading && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span
                              role="alert"
                              className="text-[11px] text-[#E02B1D] font-medium mb-0"
                            >
                              {formik.errors.email}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </>
              )}
              {isGoogleAuthLoading && (
                <div className="mb-8">
                  <Loader></Loader>
                </div>
              )}

              

              <div className="d-grid mb-[25px] flex flex-col gap-[10px]">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="bg-pc mt-[10px] border border-pc text-white rounded-full w-[100%] text-pfont font-medium py-[8px] cursor-pointer group hover:bg-white disabled:opacity-75 disabled:cursor-not-allowed"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    isGoogleAuthLoading
                  }
                >
                  {!loading && (
                    <span className="indicator-label group-hover:text-pc">
                      Continue
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress group-hover:text-pc"
                      style={{ display: "block" }}
                    >
                      Please wait...
                    </span>
                  )}
                </button>
                <div className="flex items-center py-[10px]">
                  <hr className="flex-grow border-gray-300" />
                  <p className="px-4 text-center">Or</p>
                  <hr className="flex-grow border-gray-300" />
                </div>
                <GoogleOAuthProvider clientId={clientId}>
                  <LoginButton
                    setIsLoading={setIsGoogleAuthLoading}
                    onSuccess={handleLogin}
                    onError={() => {
                      setIsGoogleAuthLoading(false);
                    }}
                    isDisabled={isGoogleAuthLoading}
                  ></LoginButton>
                </GoogleOAuthProvider>
              </div>

              {/* <div
                className="text-[#277CFB] text-center cursor-pointer"
                onClick={handleShowLoginPopup}
              >
                Already a member?{" "}
                <Link to="/auth/login" className="link-primary">
                Sign in
                </Link>
              </div> */}
              <p className="text-[14px] px-[8px]">
                We never share your information with other parties without your consent.
              </p>
            </form>
          </div>
        </>
      ) : (
        ""
      )}

      {/* show Register popup if the email is not exists */}
      {showRegisterPopup ? (
        <>
          <div
            className="fixed inset-0 flex items-center justify-center bg-[#787878cc] bg-opacity-25 z-20"
            ref={outerDivRef}
          >
            <form
              className="w-[470px] shadow-lg p-[20px] rounded-lg border bg-white"
              onSubmit={RegisterUser}
              noValidate
            >
              <div
                className="text-center border-[#1C304A85] pb-[5px] mb-[25px]"
                onClick={handleClickOutside}
              >
                <h2 className="text-h2font lg:text-[25px] text-lightblack font-bold">
                  Add a name and password
                </h2>
                <p className="text-[15px] lg:text-[19px] text-[#4F4F4F]">
                  Set a password for {userEmail} to access your account on all
                  your devices
                </p>
              </div>

              {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {!isGoogleAuthLoading && (
                <>
                  <div className="flex flex-col gap-[4px] mb-[10px]">
                    <label className="text-pfont text-lightblack font-semibold">
                      Name
                    </label>
                    <input
                      placeholder="Name"
                      {...formik.getFieldProps("name")}
                      className={clsx(
                        "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                        {
                          "is-invalid":
                            formik.touched.name && formik.errors.name,
                        },
                        {
                          "is-valid":
                            formik.touched.name && !formik.errors.name,
                        }
                      )}
                      type="text"
                      name="name"
                      autoComplete="off"
                    />
                    {formik.touched.name &&
                      formik.errors.name &&
                      !isGoogleAuthLoading && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span
                              role="alert"
                              className="text-[11px] text-[#E02B1D] font-medium mb-0"
                            >
                              {formik.errors.name}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="flex flex-col gap-[4px] mb-[10px] mb-8">
                    <label className="text-pfont text-lightblack font-semibold">
                      Password
                    </label>
                    <input
                      type="password"
                      autoComplete="off"
                      placeholder="Password"
                      {...formik.getFieldProps("password")}
                      className={clsx(
                        "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                        {
                          "is-invalid":
                            formik.touched.password && formik.errors.password,
                        },
                        {
                          "is-valid":
                            formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password &&
                      formik.errors.password &&
                      !isGoogleAuthLoading && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span
                              role="alert"
                              className="text-[11px] text-[#E02B1D] font-medium mb-0"
                            >
                              {formik.errors.password}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                  <p className="text-[15px] lg:text-[14px] lg:mb-[10px] text-[#4F4F4F]">
                    Use 8 or more characters with a combination of uppercase,
                    lowercase, a number, and a symbol.
                  </p>
                </>
              )}
              {isGoogleAuthLoading && (
                <div className="mb-8">
                  <Loader></Loader>
                </div>
              )}

              <div className="d-grid mb-[25px] flex flex-col gap-[10px]">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="bg-pc mt-[10px] border border-pc text-white rounded-full w-[100%] text-pfont font-medium py-[8px] cursor-pointer group hover:bg-white disabled:opacity-75 disabled:cursor-not-allowed"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    isGoogleAuthLoading
                  }
                >
                  {!loading && (
                    <span className="indicator-label group-hover:text-pc">
                      Create Account
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress group-hover:text-pc"
                      style={{ display: "block" }}
                    >
                      Please wait...
                    </span>
                  )}
                </button>
              </div>

              <div className="d-grid mb-[25px] flex flex-col gap-[10px] ">
                <button
                  type="button"
                  // id="kt_sign_in_submit"
                  className="bg-sc border border-pc text-white rounded-full w-[100%] text-pfont font-medium py-[8px] cursor-pointer group hover:bg-white disabled:opacity-75 disabled:cursor-not-allowed"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    isGoogleAuthLoading
                  }
                  onClick={handleBackFromRegister}
                >
                  {!loading && (
                    <span className="indicator-label group-hover:text-sc">
                      Back
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress group-hover:text-pc"
                      style={{ display: "block" }}
                    >
                      Please wait...
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        ""
      )}

      {/* show Login popup if the email is already exists */}
      {showLoginPopup ? (
        <>
          <div
            className="fixed inset-0 flex items-center justify-center bg-[#787878cc] bg-opacity-25 z-20"
            ref={outerDivRef}
          >
            <form
              className="w-[470px] shadow-lg p-[20px] rounded-lg border bg-white"
              onSubmit={LoginUser}
              noValidate
            >
              <div
                className="text-center border-[#1C304A85] pb-[5px] mb-[25px]"
                onClick={handleClickOutside}
              >
                <h2 className="text-h2font lg:text-[25px] text-lightblack font-bold">
                  Welcome back!
                </h2>
                <p className="text-[15px] lg:text-[19px] text-[#4F4F4F]">
                  Please enter a password associated with {userEmail} to
                  continue.
                </p>
              </div>

              {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {!isGoogleAuthLoading && (
                <>
                  <div className="flex flex-col gap-[4px] mb-[10px] mb-8">
                    <label className="text-pfont text-lightblack font-semibold">
                      Password
                    </label>
                    <input
                      type="password"
                      autoComplete="off"
                      placeholder="Password"
                      {...formik.getFieldProps("password")}
                      className={clsx(
                        "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                        {
                          "is-invalid":
                            formik.touched.password && formik.errors.password,
                        },
                        {
                          "is-valid":
                            formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password &&
                      formik.errors.password &&
                      !isGoogleAuthLoading && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span
                              role="alert"
                              className="text-[11px] text-[#E02B1D] font-medium mb-0"
                            >
                              {formik.errors.password}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </>
              )}
              {isGoogleAuthLoading && (
                <div className="mb-8">
                  <Loader></Loader>
                </div>
              )}

              <div className="d-grid mb-[25px] flex flex-col gap-[10px]">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="bg-pc mt-[10px] border border-pc text-white rounded-full w-[100%] text-pfont font-medium py-[8px] cursor-pointer group hover:bg-white disabled:opacity-75 disabled:cursor-not-allowed"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    isGoogleAuthLoading
                  }
                >
                  {!loading && (
                    <span className="indicator-label group-hover:text-pc">
                      Login
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress group-hover:text-pc"
                      style={{ display: "block" }}
                    >
                      Please wait...
                    </span>
                  )}
                </button>
              </div>

              <div className="d-grid mb-[25px] flex flex-col gap-[10px] ">
                <button
                  type="button"
                  // id="kt_sign_in_submit"
                  className="bg-sc border border-pc text-white rounded-full w-[100%] text-pfont font-medium py-[8px] cursor-pointer group hover:bg-white disabled:opacity-75 disabled:cursor-not-allowed"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    isGoogleAuthLoading
                  }
                  onClick={handleBackFromLogin}
                >
                  {!loading && (
                    <span className="indicator-label group-hover:text-sc">
                      Back
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress group-hover:text-pc"
                      style={{ display: "block" }}
                    >
                      Please wait...
                    </span>
                  )}
                </button>
              </div>
              <div className="d-grid mb-[25px] flex flex-col gap-[10px] items-end">
                {!isResetPassword && (
                  <button
                    type="button"
                    className="text-pc text-right hover:underline w-fit"
                    onClick={handleForgotPassword}
                  >
                    Forgot password?
                  </button>
                )}
                {isResetPassword && <span>{resetPasswordMsg}</span>}
              </div>
            </form>
          </div>
        </>
      ) : (
        ""
      )}

      {/* show Login Success popup*/}
      {showLoginSuccessPopup ? (
        <>
          <div
            className="fixed inset-0 flex items-center justify-center bg-[#787878cc] bg-opacity-25 z-20"
            ref={outerDivRef}
          >
            <form
              className="w-[470px] shadow-lg p-[20px] rounded-lg border bg-white"
              onSubmit={LoginUser}
              noValidate
            >
              <div
                className="text-center border-[#1C304A85] pb-[5px] mb-[25px]"
                onClick={handleClickOutside}
              >
                <h2 className="text-h2font lg:text-[25px] text-lightblack font-bold">
                  Sign-in successful!
                </h2>
                <p className="text-[15px] lg:text-[19px] text-[#4F4F4F]">
                  You now have access to more from CureValue
                </p>
              </div>

              <div className="flex justify-center">
                <img
                  src="/media/login_form_icons/login_success_icon.jpg"
                  alt=""
                  className="pb-[10px] max-w-[133px] h-[230px] lg:max-w-[175px] max-h-[175px] rounded-[30px] aspect-[16/9]"
                />
              </div>

              {/* {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {!isGoogleAuthLoading && (
                <>
                  <p>Image or other data here</p>
                </>
              )}
              {isGoogleAuthLoading && (
                <div className="mb-8">
                  <Loader></Loader>
                </div>
              )} */}

              <div className="d-grid mb-[25px] flex flex-col gap-[10px]">
                <button
                  type="button"
                  // id="kt_sign_in_submit"
                  className="bg-pc border border-pc text-white rounded-full w-[100%] text-pfont font-medium py-[8px] cursor-pointer group hover:bg-white disabled:opacity-75 disabled:cursor-not-allowed"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    isGoogleAuthLoading
                  }
                  onClick={handleContinueBtn}
                >
                  {!loading && (
                    <span className="indicator-label group-hover:text-pc">
                      Continue
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress group-hover:text-pc"
                      style={{ display: "block" }}
                    >
                      Please wait...
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        ""
      )}

      {/* show Register Success popup*/}
      {showRegisterSuccessPopup ? (
        <>
          <div
            className="fixed inset-0 flex items-center justify-center bg-[#787878cc] bg-opacity-25 z-20"
            ref={outerDivRef}
          >
            <form
              className="form w-[470px] shadow-lg p-[20px] rounded-lg border bg-white flex flex-col items-center"
              onSubmit={LoginUser}
              noValidate
            >
              {/* Text Content */}
              <div
                className="text-center border-[#1C304A85] pb-[5px] mb-[25px]"
                onClick={handleClickOutside}
              >
                <h2 className="text-h2font lg:text-[25px] text-lightblack font-bold">
                  Account created!
                </h2>
                <p className="text-[15px] lg:text-[19px] text-[#4F4F4F]">
                  Enjoy exclusive access and savings on CureValue
                </p>
              </div>
              {/* 
              {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {!isGoogleAuthLoading && (
                <>
                  <p>Image or other data here</p>
                </>
              )}
              {isGoogleAuthLoading && (
                <div className="mb-8">
                  <Loader></Loader>
                </div>
              )} */}
              {/* Tourism Image */}
              <div className="flex justify-center">
                <img
                  src="/media/login_form_icons/login_success_icon.jpg"
                  alt="CureValue tourism"
                  className="pb-[10px] max-w-[133px] h-[230px] lg:max-w-[175px] max-h-[175px] rounded-[30px] aspect-[16/9] mt-[-30px]"
                />
              </div>
                {/* Continue button */}
              <div className="d-grid mb-[25px] flex flex-col gap-[10px] w-full">
                <button
                  type="button"
                  // id="kt_sign_in_submit"
                  className="bg-pc border border-pc text-white rounded-full w-[100%] text-pfont font-medium py-[8px] cursor-pointer group hover:bg-white disabled:opacity-75 disabled:cursor-not-allowed"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    isGoogleAuthLoading
                  }
                  onClick={handleContinueBtn}
                >
                  {!loading && (
                    <span className="indicator-label group-hover:text-pc">
                      Continue
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress group-hover:text-pc"
                      style={{ display: "block" }}
                    >
                      Please wait...
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SignUpPopup;
