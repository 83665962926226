import { useEffect, useRef, useState } from "react";
import { BiPlusMedical } from "react-icons/bi";
import { FaLocationDot, FaUserDoctor } from "react-icons/fa6";
import { useDebounce } from "use-debounce";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import { RxCross2 } from "react-icons/rx";
import {
  TreatmentContextModel,
  findTreatment,
  useTreatment,
} from "../../modules/treatment";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaSearchPlus } from "react-icons/fa";
import {
  constructURLSegment,
  getURLFromString,
} from "../../../helpers/CVHelper";
import { ImCross } from "react-icons/im";
interface TreatmentFormProps {
  onSelectionChange: (selectedTreatment: TreatmentContextModel) => void;
  defaultSelection?: TreatmentContextModel;
}
const TreatmentForm = ({
  defaultSelection,
  onSelectionChange,
}: TreatmentFormProps) => {
  const { setSelectedTreatment } = useTreatment();
  const navigate = useNavigate();
  const [openFacilitiesList, setOpenFacilitiesList] = useState(false);
  const treatmentSelectRef = useRef<HTMLDivElement>(null);
  const [treatment, setTreatment] = useState("");
  const [isTreatmentSelected, setIsTreatmentSelected] = useState(false);
  const [loadingTreatments, setLoadingTrestments] = useState(false);
  const [debouncedTreatment] = useDebounce(treatment, 100);
  const [treatments, setTreatments] = useState([]);
  useEffect(() => {
    if (debouncedTreatment.length > 1 && !isTreatmentSelected) {
      fetchTreatment(debouncedTreatment);
    }
  }, [debouncedTreatment]);
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    if (defaultSelection) {
      setIsTreatmentSelected(true);
      setTreatment(
        defaultSelection.subTreatment
          ? defaultSelection.subTreatment
          : defaultSelection.treatment
      );
    }
  }, [defaultSelection]);
  const openFacilities = () => {
    //setOpenFacilitiesList(true);
  };
  const handleOutsideClick = (ev: MouseEvent) => {
    assertIsNode(ev.target);
    if (
      treatmentSelectRef.current &&
      !treatmentSelectRef.current.contains(ev.target)
    ) {
      setOpenFacilitiesList(false);
    }
  };
  function assertIsNode(e: EventTarget | null): asserts e is Node {
    if (!e || !("nodeType" in e)) {
      throw new Error(`Node expected`);
    }
  }
  const fetchTreatment = async (treatment: string, openList = true) => {
    const { data, error } = await CureValueService(
      findTreatment,
      {
        search: treatment,
      },
      {
        setIsLoading: setLoadingTrestments,
      }
    );
    if (data) {
      setTreatments(data);
      setOpenFacilitiesList(true);
    }
  };
  const treatmentClicked = (treatment: any) => {
    setIsTreatmentSelected(true);
    setOpenFacilitiesList(false);
    setTreatment(treatment.subTreatment);
    onSelectionChange({
      subTreatment: treatment.subTreatment,
      subTreatmentId: treatment._id,
      treatment: treatment.treatmentName,
      treatmentId: treatment.treatment_id,
    });
    // navigate(
    //   `/cityselection?service=${getURLFromString(treatment.treatmentName)}`
    // );
    navigate(
      `/treatment/${constructURLSegment(treatment.treatmentName)}/city-filter`
    );
  };
  // return (
  //   <div className="min-w-[350px] rounded-[16px] bg-white">
  //     <div className="px-[10px] lg:px-[20px] gap-[10px] lg:gap-[12px] py-[10px] lg:py-[20px]  bg-[#E9ECF0] flex items-center rounded-t-[16px]">
  //       <FaLocationDot className="text-cardpara text-[18px] lg:text-[24px]" />
  //       <p className="text-cardpara font-medium text-smalltext lg:text-pfont whitespace-nowrap">
  //         I am looking for
  //       </p>
  //     </div>
  //     <div className="bg-white px-[10px] pb-[15px] rounded-b-[16px]">
  //       <div className="mb-[15px]">
  //         <div
  //           className="relative bg-white flex items-center gap-[10px] py-[20px] lg:py-[10px] lg:gap-[20px] pl-[10px] lg:pl-[10px]"
  //           ref={treatmentSelectRef}
  //         >
  //           <BiPlusMedical className="text-cardpara text-[18px] lg:text-[24px]" />
  //           <input
  //             className="lg:text-pfont text-extraclr1 bg-transparent w-[100%] h-[40px] focus:border-none placeholder-gray-600 focus-visible:outline-none text-ellipsis"
  //             onClick={openFacilities}
  //             // onKeyUp={trearmentKeyUpHandler}
  //             value={treatment}
  //             onChange={(e) => {
  //               setIsTreatmentSelected(false);
  //               setTreatment(e.target.value);
  //             }}
  //             placeholder="Enter the treatment"
  //           />
  //           {openFacilitiesList && debouncedTreatment.length > 3 && (
  //             <div className="bg-white shadow-boxshadow h-auto absolute  z-20  top-[40px] lg:top-[60px] left-0 w-[100%] border-t-[5px] border-hoverclr rounded-b-[10px] pb-[20px]">
  //               {loadingTreatments == false ? (
  //                 <ul className="pt-[20px] px-[20px] flex flex-col gap-[10px] max-h-[200px] overflow-y-scroll">
  //                   {treatments.length != 0 ? (
  //                     treatments.map((treatment: any) => {
  //                       return (
  //                         <li key={treatment._id}>
  //                           <a onClick={(e) => treatmentClicked(treatment)}>
  //                             {treatment.subTreatment}
  //                           </a>
  //                         </li>
  //                       );
  //                     })
  //                   ) : (
  //                     <li>
  //                       <span>Unable to find the treatment</span>
  //                     </li>
  //                   )}
  //                 </ul>
  //               ) : (
  //                 <div className="pt-[20px]">
  //                   <Loader />
  //                 </div>
  //               )}
  //             </div>
  //           )}
  //         </div>
  //         {/* <div className="relative bg-white flex items-center gap-[10px] pb-[10px] lg:py-[10px] lg:gap-[20px] pl-[10px] lg:pl-[10px]">
  //           <FaLocationDot className="text-cardpara text-[18px] lg:text-[24px]" />
  //           <div
  //             className="font-semibold text-smalltext lg:text-pfont text-extraclr1 bg-transparent appearance-none cursor-pointer w-[100%]"
  //             onClick={openFacilities}
  //           >
  //             <p>In</p>
  //           </div>
  //         </div> */}
  //       </div>
  //       <div>
  //         <button
  //           className="bg-pc text-white text-center w-[100%] py-[20px] font-bold rounded-[8px] "
  //           onClick={() => {
  //             if (treatment) {
  //               navigate("/cityselection");
  //             }
  //           }}
  //         >
  //           Search
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <div className="min-w-[350px] rounded-[16px] ">
      <div className=" px-[10px]  rounded-[16px]">
        <div className="px-[10px] lg:px-[20px] gap-[10px] lg:gap-[12px] py-[10px] lg:py-[20px]  bg-[#E9ECF0] flex items-center rounded-t-[16px]">
          <p className="text-cardpara font-medium text-h4font lg:text-h4font whitespace-nowrap">
            I am looking for{" "}
          </p>
        </div>
        <div className="px-[10px] lg:px-[20px] py-[30px] border-[#E9ECF0] border-[1px] border-solid rounded-b-[16px] bg-white">
          <div
            className="relative flex items-center gap-[10px]  lg:gap-[20px]"
            ref={treatmentSelectRef}
          >
            <span>
              <FaUserDoctor className="text-extraclr1 text-[18px] lg:text-[24px]" />
            </span>
            <div className="flex-grow relative mt-50">
              <input
                className="text-[18px] peer relative  z-10 lg:text-h4font border-extraclr1 text-black bg-transparent w-[100%] h-[40px] focus:border-t-none focus:border-r-none  placeholder-gray-600 focus-visible:outline-none focus-visible:border-b-2 text-ellipsis border-b-2 border-black"
                onClick={openFacilities}
                //onKeyUp={trearmentKeyUpHandler}
                value={treatment}
                onChange={(e) => {
                  setIsTreatmentSelected(false);
                  setTreatment(e.target.value);
                }}
              />
              <label
                className={`absolute  overflow-hidden whitespace-nowrap left-0  z-1 peer-focus:top-[-18px] peer-focus:text-anchortext transition-all text-extraclr1
                   ${
                     treatment
                       ? "top-[-18px] text-anchortext"
                       : "top-[5px] text-h4font "
                   }
                `}
              >
                treatment or condition
                {/* <div className="flex min-h-screen items-center justify-center bg-gradient-to-tr to-blue-400 from-green-500 p-10">
                  <div className="w-max">
                    <h1 className="animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-5 text-5xl text-white font-bold">
                      Hello World
                    </h1>
                  </div>
                </div> */}
              </label>
            </div>

            <span className="flex gap-[10px]">
              <RxCross2
                className={` text-[18px] lg:text-[24px]  cursor-pointer ${
                  treatment ? "text-gray-400 hover:text-pc" : "text-white"
                }`}
                onClick={() => setTreatment("")}
              />

              <FaSearch
                className="text-black text-[18px] lg:text-[24px] cursor-pointer"
                onClick={() => {
                  if (defaultSelection && treatment != "")
                    navigate(
                      `/treatment/${constructURLSegment(
                        defaultSelection?.treatment
                      )}/city-filter`
                    );
                }}
              />
            </span>
            {openFacilitiesList && debouncedTreatment.length > 1 && (
              <div className="bg-white shadow-boxshadow h-auto absolute  z-20  top-[38px] lg:top-[38px] left-0 w-[100%] border-t-[5px] border-hoverclr rounded-b-[10px] pb-[20px]">
                {loadingTreatments == false ? (
                  <ul className="pt-[20px] px-[20px] flex flex-col gap-[10px] max-h-[200px] overflow-y-scroll">
                    {treatments.length != 0 ? (
                      treatments.map((treatment: any) => {
                        // console.log(treatments)
                        return (
                          <li key={treatment._id}>
                            <a onClick={(e) => treatmentClicked(treatment)}>
                              {treatment.subTreatment}
                            </a>
                          </li>
                        );
                      })
                    ) : (
                      <li>
                        <span>Unable to find the treatment</span>
                      </li>
                    )}
                  </ul>
                ) : (
                  <div className="pt-[20px]">
                    <Loader />
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <div className="relative bg-white flex items-center gap-[10px] pb-[10px] lg:py-[10px] lg:gap-[20px] pl-[10px] lg:pl-[10px]">
            <FaLocationDot className="text-cardpara text-[18px] lg:text-[24px]" />
            <div
              className="font-semibold text-smalltext lg:text-pfont text-extraclr1 bg-transparent appearance-none cursor-pointer w-[100%]"
              onClick={openFacilities}
            >
              <p>In</p>
            </div>
          </div> */}
        </div>
        {/* <div>
          <button
            className="bg-pc text-white text-center w-[100%] py-[20px] font-bold rounded-[8px] "
            onClick={() => {
              if (treatment) {
                navigate("/cityselection");
              }
            }}
          >
            Search
          </button>
        </div> */}
      </div>
    </div>
  );
};
export default TreatmentForm;
