import React, { useRef, useState, MouseEvent,useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
// Module CSS
import styles from './FacilityCityCard.module.css'
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import cityimg from "../../../assets/images/city1.png";
import citymobimg from "../../../assets/images/citymob.png";
import trustimg from "../../../assets/images/trustmark2.png";
import { PiMedalFill } from "react-icons/pi";
import CureValueRating from "../shared/CureValueRating";
import treatmentColourMap from "../../modules/common/core/treatmentColourMap.json";
import { url } from "inspector";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useAuth } from "../../modules/auth";
import { AddRemoveFavoriteRequest } from "../../modules/favorite/core/_models";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import {
  addToFavorite,
  removeFromFavorite,
} from "../../modules/favorite/core/FavoriteService";
import { Facility } from "../../modules/facility";
import { Treatment, useTreatment } from "../../modules/treatment";
import { getURLFromString } from "../../../helpers/CVHelper";
import defaultFacilityImage from "../../../assets/images/cv-hospital-illustration.jpg";
import { FACILITY_IMAGE_CDN } from "../shared/urlConstants";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // Import Tippy's CSS

const FacilityCityCard = ({
  facility: facilityProps,
  countryName,
  cityName,
  city,
  selectedFacilities,
  handleCompareClick,
  handleCheckboxChange,
  showLimitError,
  limitExceededFacility,
  handleShowSignUpPopup,
}: any) => {
  const navigate = useNavigate();
  let userSelectedTreatment = useTreatment();
  const sliderRef = useRef<any>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isopenFilter, setIsOpenFilter] = useState(false);
  const { currentUser } = useAuth();
  const [facility, setFacility] = useState<Facility>(facilityProps);
  const { selectedTreatment } = useTreatment();




  const toggleModal = () => {
    // e.preventDefault();
    setIsOpenModal(!isOpenModal);
  };

  const handlePrevClick = (event: any) => {
    event.stopPropagation();
    sliderRef.current.slickPrev();
  };

  const handleNextClick = (event: any) => {
    event.stopPropagation();
    sliderRef.current.slickNext();
  };

  var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <FaRegArrowAltCircleLeft onClick={handlePrevClick} />,
    nextArrow: <FaRegArrowAltCircleRight onClick={handleNextClick} />,
  };

  const handleCardClick = () => {
    if (selectedTreatment?.treatment) {
      navigate(
        "/location/" +
          getURLFromString(facility.country_name) +
          "/" +
          getURLFromString(facility.city_name) +
          "/" +
          getURLFromString(facility.facility_name) +
          "?service=" +
          getURLFromString(selectedTreatment?.treatment)
      );
    }
  };
  const getTreatmentColour = (treatment: string) => {
    const colour = JSON.parse(JSON.stringify(treatmentColourMap))[treatment];

    return colour;
  };
  const favoriteClickHandle = (e: MouseEvent) => {
    e.stopPropagation();
    if (currentUser) {
      changeFavorite();
    } else {
      handleShowSignUpPopup?.();
      //navigate("/auth/login");
    }
  };
  const changeFavorite = async () => {
    let payload: AddRemoveFavoriteRequest = {
      favourite_type: "facility",
      facility_id: facility?._id,
    };
    if (
      facility?.is_favourite == false ||
      facility?.is_favourite == undefined
    ) {
      const { data, error } = await CureValueService(addToFavorite, payload);
      if (data) {
        if (facility) {
          setFacility({ ...facility, is_favourite: true });
        }
      }
    } else {
      const { data, error } = await CureValueService(
        removeFromFavorite,
        payload
      );
      if (data) {
        if (facility) {
          setFacility({ ...facility, is_favourite: false });
        }
      }
    }
  };
  return (
    <>
      {/* <Helmet>
        <title>{facility.facility_name || "CureValue Facility"}</title>
        <meta property="og:title" content={facility.facility_name || "CureValue Facility"} />
        <meta
          property="og:description"
          content={facility.facility_description || "Discover top healthcare facilities."}
        />
        <meta
          property="og:image"
          content={
            facility.image_urls.length
              ? `${FACILITY_IMAGE_CDN}/${facility._id}/${facility.image_urls[0]}`
              : defaultFacilityImage
          }
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={facility.facility_name || "CureValue Facility"} />
        <meta
          name="twitter:description"
          content={facility.facility_description || "Find top healthcare facilities."}
        />
        <meta
          name="twitter:image"
          content={
            facility.image_urls.length
              ? `${FACILITY_IMAGE_CDN}/${facility._id}/${facility.image_urls[0]}`
              : defaultFacilityImage
          }
        />
      </Helmet> */}
      <div
        className=" h-fit lg:h-[300px] flex flex-col lg:flex-row rounded-[30px] gap-[10px] w-[100%] xl:shadow-cardshadow citycard lg:border lg:border-borderclr"
        onClick={() => {
          handleCardClick();
        }}
      >
        <div className=" lg:w-[300px] h-auto relative rounded-l-[30px] city-card-mac">
          <div
            className="absolute z-10 top-[15px] right-[15px] text-[20px] p-[8px] bg-white text-hoverclr rounded-full cursor-pointer"
            onClick={(e) => favoriteClickHandle(e)}
          >
            {facility?.is_favourite == false ||
            facility?.is_favourite == undefined ? (
              <IoMdHeartEmpty />
            ) : (
              <IoMdHeart />
            )}
          </div>
          <div className="desktopslider block h-[100%] w-full lg:w-[300px] ">
            <Slider
              {...settings}
              ref={sliderRef}
              infinite={facility.image_urls.length <= 1 ? false : true}
            >
              {facility.image_urls.length != 0 &&
                facility.image_urls.map((imgUrl: any, index: any) => {
                  return (
                    <div
                      key={index + 1}
                      className=" w-full lg:w-[300px] h-[100px]"
                    >
                      <div
                        className="w-full h-fit bg-[#5f697b] lg:rounded-l-[30px] rounded lg:rounded-r-[0px] justify-center flex items-center"
                        // style={{ backgroundImage: `url(${imgUrl})` }}
                      >
                        <img
                          src={`${
                            FACILITY_IMAGE_CDN + facility?._id + "/" + imgUrl
                          }`}
                          // className="h-[300px] lg:h-full aspect-square w-full lg:rounded-l-[30px] rounded lg:rounded-r-[0px] object-contain bg-extraclr1"
                          className={`h-[300px] lg:h-full aspect-square lg:rounded-l-[30px] rounded lg:rounded-r-[0px] object-contain `}
                          alt={`Top ${selectedTreatment?.treatment.toLowerCase()} treatment facility`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = defaultFacilityImage;
                          }}

                          // https://project-s3-curevalue-backend-dev.s3.ap-southeast-2.amazonaws.com/curevalue/facility/662284a2900b62b6ab0a5390/images/facility_images/https%3Alh5.googleusercontent.compAF1QipP33l9gF0PfVUDfNJKkMqO8gyXImsZ1kVWSTKk%3Dw203-h226-k-no?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATUCFNEMWLAG7JIPH%2F20240618%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20240618T124808Z&X-Amz-Expires=3600&X-Amz-Signature=16d624aa35c52a647a3f6ba10d62fd05abbb818785a4158530f15efc757b242a&X-Amz-SignedHeaders=host
                        />
                      </div>
                    </div>
                  );
                })}
              {facility.image_urls.length == 0 && (
                <>
                  <div className="w-[300px] h-[100px]">
                    <div
                      className="w-fit h-fit bg-[#5f697b] lg:rounded-l-[30px] rounded lg:rounded-r-[0px]"
                      // style={{ backgroundImage: `url(${imgUrl})` }}
                    >
                      <img
                        src={defaultFacilityImage}
                        // className="h-[300px] lg:h-full aspect-square w-full lg:rounded-l-[30px] rounded lg:rounded-r-[0px] object-contain bg-extraclr1"
                        className={`h-[300px] lg:h-full aspect-square lg:rounded-l-[30px] rounded lg:rounded-r-[0px] object-contain w-[300px]`}
                        alt={`facility-default`}

                        // https://project-s3-curevalue-backend-dev.s3.ap-southeast-2.amazonaws.com/curevalue/facility/662284a2900b62b6ab0a5390/images/facility_images/https%3Alh5.googleusercontent.compAF1QipP33l9gF0PfVUDfNJKkMqO8gyXImsZ1kVWSTKk%3Dw203-h226-k-no?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATUCFNEMWLAG7JIPH%2F20240618%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20240618T124808Z&X-Amz-Expires=3600&X-Amz-Signature=16d624aa35c52a647a3f6ba10d62fd05abbb818785a4158530f15efc757b242a&X-Amz-SignedHeaders=host
                      />
                    </div>
                  </div>
                </>
              )}
            </Slider>
          </div>
          {/* <div className="mobileslider block lg:hidden">
            <Slider {...settings} ref={sliderRef}>
              {facility.image_urls.map((imgUrl: any, index: any) => {
                return <img src={imgUrl} className="" />;
              })}
            </Slider>
          </div> */}

          {facility.image_urls.length > 0 ? (
            <div className="slider-arrows flex items-center justify-between w-full  absolute left-0 right-5 top-0 bottom-0 m-auto">
              <div className="prev-arrow" onClick={handlePrevClick}>
                <FaRegArrowAltCircleLeft className="text-white cursor-pointer text-regsize hover:text-hoverclr" />
              </div>
              <div className="next-arrow" onClick={handleNextClick}>
                <FaRegArrowAltCircleRight className="text-white cursor-pointer text-regsize hover:text-hoverclr" />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="justify-between py-[18px] lg:pr-[26px] hidden lg:flex">
          <div className="lg:max-w-[70%] citycard-desc">
            <h2 className="text-h4font lg:text-[22px] text-pc lg:text-sc font-semibold">
              {facility.facility_name}
            </h2>
            <p className="text-anchortext text-pc lg:text-sc lg:text-[18px] font-medium secondcity">
              {facility.city_name}, {facility.country_name}
            </p>
            <div className="flex flex-wrap items-center gap-[10px] pt-[16px] xl:pt-[9px] pb-[12px]">
              {facility?.treatments_offered.map((treatment: Treatment) => {
                return (
                  <p
                    className={`text-[10px] xl:text-anchortext font-medium px-[8px] py-[2px] ${getTreatmentColour(
                      treatment.name
                    )}`}
                    key={Math.random()}
                  >
                    {treatment.name}
                  </p>
                );
              })}
              {/* <p className="text-smalltext font-medium px-[8px] py-[2px] bg-[#1584441c] text-extraclr4">
                  Cardiology
                </p>
                <p className="text-smalltext font-medium px-[8px] py-[2px] bg-[#98521138] text-extraclr5">
                  Dentistry
                </p> */}
            </div>
            {facility.accreditations.length != 0 && (
              <div className="flex items-center gap-[5px] pb-[6px] lg:pb-[12px]">
                <PiMedalFill className="text-cardpara text-[35px] lg:text-[18px]" />
                <p className="font-medium text-cardpara lg:text-anchortext">
                  {facility.accreditations.map((accreditation: any,index: number) => {
                     // Add a comma only if there are more than 2 items and it's not the last item
                   return (
                    accreditation +
                    (facility.accreditations.length > 2 && index < facility.accreditations.length - 1 ? ", " : "")
                  );
                  })}
                </p>
              </div>
            )}
            <p className="pb-[12px] text-extraclr1 mb-[5px] hidden xl:block lg:text-anchortext max-h-[100px] overflow-y-scroll description-scrollbar  text-justify">
              {facility.facility_description}
            </p>
            <a
              className="underline text-[#006AF5] font-medium cursor-pointer lg:text-anchortext"
              onClick={toggleModal}
            >
              View details
            </a>

            {/* Conditionally render CompareBar */}
            {/* {selectedFacilities.length > 0 && (
              <CompareBar
                key={selectedFacilities.length}
                selectedFacilities={selectedFacilities}
                clearFacilities={clearFacilities}
                setSelectedFacilities={setSelectedFacilities}
                uncheckFacility={uncheckFacility}
              />
            )} */}
          </div>
          <div className="w-[23%] xl:w-[70%] flex flex-col justify-evenly items-end text-right">
            {/* <IoMdInformationCircleOutline className="text-[30px] text-iconclr2" /> */}

            <CureValueRating
              rating={facility.curevalue_rating}
              size="xl"
            ></CureValueRating>

            <div className="cardprice">
              {/* <h2 className="text-sc text-h3font lg:text-[29px] font-bold">
                  ${facility.treatment_cost}
                </h2> */}
              <h2 className="text-sc text-h3font lg:text-h2font font-bold ">
                {facility.affordability_index &&
                  Math.round(100 - parseFloat(facility.affordability_index))}
                % Savings
              </h2>
              {/* <p className="text-greyclr text-anchortext lg:text-pfont font-medium">
                on average
              </p> */}
              {/* <p className="text-greyclr text-anchortext lg:text-pfont font-medium bg-[#fecb4d] rounded">
                Compared to the U.S.
              </p> */}
              <div className="flex items-center gap-[5px] justify-end">
                <Tippy
                  content="You can only compare 4 facilities at a time."
                  visible={limitExceededFacility === facility._id} // Show only for the matching checkbox
                  placement="bottom"
                  className="hidden lg:block"
                >
                  <input
                    key={`checkbox-${facility._id}`}
                    value={facility._id}
                    id={`compare-checkbox-${facility._id}`}
                    type="checkbox"
                    checked={selectedFacilities.some((item: Facility) => item._id === facility._id)}
                    className="peer cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-[#e5803c] checked:border-[#e5803c] checked:after:content-['✓'] checked:after:text-white checked:after:block checked:after:w-full checked:after:h-full checked:after:text-center checked:after:font-bold checked:after:text-[10px]"
                    style={{ width: "16px", height: "16px",marginTop:"-2px" }} 
                    onChange={() => {
                      console.log(`Checkbox clicked for facility ID: ${facility._id}`);
                      handleCheckboxChange(facility);
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevents the propagation
                  />
                </Tippy>
                <label
                  htmlFor="compareCheckbox"
                  className="cursor-pointer text-base sm:text-sm md:text-base lg:text-lg"
                  style={{ fontSize: '15px' }} 
                  onClick={(e) => e.stopPropagation()} // Prevent propagation for label too
                >
                  Add to compare
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile responsive portion below */}
        <div className="flex lg:hidden justify-between py-[18px] lg:pr-[26px] flex-col gap-3 border-borderclr border-b">
          <div>
            <h2 className="text-h4font lg:text-[22px] text-pc lg:text-sc font-semibold">
              {facility.facility_name}
            </h2>
            <p className="text-anchortext text-pc lg:text-sc lg:text-[18px] font-medium secondcity">
              {facility.city_name}, {facility.country_name}
            </p>
          </div>
          <div className="flex gap-2 flex-wrap">
            {facility?.treatments_offered.map((treatment: Treatment) => {
              return (
                <p
                  className={`text-[10px] xl:text-anchortext font-medium px-[8px] py-[2px] ${getTreatmentColour(
                    treatment.name
                  )}`}
                  key={Math.random()}
                >
                  {treatment.name}
                </p>
              );
            })}
          </div>
          <div className="flex justify-between items-end">
            <div className="flex items-start gap-[5px] pb-[6px] lg:pb-[12px] max-w-[50%] flex-col ">
              {facility.accreditations.length != 0 && (
                <div>
                  <PiMedalFill className="text-cardpara text-[30px] lg:text-[18px]" />
                  <p className="font-medium text-cardpara lg:text-anchortext">
                    {facility.accreditations.map((accreditation: any,index: number) => {
                      return(
                        accreditation +
                        (facility.accreditations.length > 2 && index < facility.accreditations.length - 1 ? ", " : "")
                      );
                    })}
                  </p>
                </div>
              )}
              <div>
                <a
                  className={`underline text-[#006AF5] font-medium cursor-pointer lg:text-anchortext ${styles.veiwLink}`}
                  onClick={toggleModal}
                >
                  View more
                </a>
              </div>
            </div>
            <div className={`flex items-center gap-2 lg:gap-5 justify-end mb-[0.5rem] lg:mb-6 ${styles.compareCheckdiv}`}>
                <Tippy
                  content="You can only compare 4 facilities at a time."
                  visible={limitExceededFacility === facility._id} // Show only for the matching checkbox
                  placement="bottom"
                  className="lg:hidden"
                >
                  <input
                    key={`checkbox-${facility._id}`}
                    value={facility._id}
                    id={`compare-checkbox-${facility._id}`}
                    type="checkbox"
                    checked={selectedFacilities.some((item: Facility) => item._id === facility._id)}
                    className="peer cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-[#e5803c] checked:border-[#e5803c] checked:after:content-['✓'] checked:after:text-white checked:after:block checked:after:w-full checked:after:h-full checked:after:text-center checked:after:font-bold checked:after:text-[10px]"
                    style={{ width: "16px", height: "16px",marginTop:"-2px" }} 
                    onChange={() => {
                      console.log(`Checkbox clicked for facility ID: ${facility._id}`);
                      handleCheckboxChange(facility);
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevents the propagation
                  />
                </Tippy>
                {/* </Tippy> */}
              <label
                htmlFor={`compare-checkbox-${facility._id}`}
                className={styles.compareButton}
                onClick={(e) => e.stopPropagation()} // Prevent propagation for label too
              >
                Add to compare
              </label>
            </div>
            {/* {selectedFacilities.length > 0 && (
              <CompareBar
                key={selectedFacilities.length}
                selectedFacilities={selectedFacilities}
                clearFacilities={clearFacilities}
                setSelectedFacilities={setSelectedFacilities}
                uncheckFacility={uncheckFacility}
              />
            )} */}
            <div className="max-w-[50%]">
              <h2 className={`text-sc text-h3font lg:text-[16px] font-bold ${styles.savingsTitle}`}>
                {facility.affordability_index &&
                  Math.round(100 - parseFloat(facility.affordability_index))}
                % Savings
              </h2>
              {/* <p className="text-greyclr text-anchortext lg:text-pfont font-medium">
                on average
              </p> */}
              {/* <p className="text-greyclr text-anchortext lg:text-pfont font-medium bg-[#fecb4d] rounded">
                Compared to the U.S.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilityCityCard;
